import { CHANGELOG } from "utils/constant";
import {
  SET_ERRORS,
  SET_FROM_LOGIN,
  SET_MENU,
  SW_INIT,
  SW_UPDATE,
  SET_TRIGGER_TABLE,
  SET_LOADING,
  SET_TABLE_DATA,
} from "./type";

let appState = {
  menu: [],
  table: [],
  errors: null,
  loading: null,
  fromLogin: false,
  changelog: CHANGELOG,
  serviceWorkerUpdated: false,
  serviceWorkerInitialized: false,
  serviceWorkerRegistration: null,
  triggerTable: false,
};

const appReducers = (state = appState, action) => {
  switch (action.type) {
    case SET_MENU:
      return { ...state, menu: action.payload };
    case SW_INIT:
      return {
        ...state,
        serviceWorkerInitialized: !state.serviceWorkerInitialized,
      };
    case SW_UPDATE:
      return {
        ...state,
        serviceWorkerUpdated: !state.serviceWorkerUpdated,
        serviceWorkerRegistration: action.payload,
      };
    case SET_ERRORS:
      return { ...state, errors: action.payload };
    case SET_TRIGGER_TABLE:
      return { ...state, triggerTable: action.payload };
    case SET_FROM_LOGIN:
      return { ...state, fromLogin: action.payload };
    case SET_LOADING:
      return { ...state, loading: action.payload };
    case SET_TABLE_DATA:
      return { ...state, table: action.payload };
    default:
      return state;
  }
};

export default appReducers;
