const DUMMY_DATAS_TABLE = [
  {
    no: "1.",
    nama_kegiatan: "Pengembangan Kawasan",
    opd: "Dinas Pertanian",
    deadline: "23-01-2022",
    status: "Sedang dikerjakan",
    penyedia_jasa: "CV. Harapan Jasa",
    progres: 38,
  },
  {
    no: "2.",
    nama_kegiatan: "Pengembangan Kawasan",
    opd: "Dinas Pertanian",
    deadline: "23-01-2022",
    status: "Sedang dikerjakan",
    penyedia_jasa: "CV. Harapan Jasa",
    progres: 38,
  },
  {
    no: "3.",
    nama_kegiatan: "Pengembangan Kawasan",
    opd: "Dinas Pertanian",
    deadline: "23-01-2022",
    status: "Sedang dikerjakan",
    penyedia_jasa: "CV. Harapan Jasa",
    progres: 38,
  },
  {
    no: "4.",
    nama_kegiatan: "Pengembangan Kawasan",
    opd: "Dinas Pertanian",
    deadline: "23-01-2022",
    status: "Sedang dikerjakan",
    penyedia_jasa: "CV. Harapan Jasa",
    progres: 38,
  },
  {
    no: "5.",
    nama_kegiatan: "Pengembangan Kawasan",
    opd: "Dinas Pertanian",
    deadline: "23-01-2022",
    status: "Sedang dikerjakan",
    penyedia_jasa: "CV. Harapan Jasa",
    progres: 38,
  },
];

const CHANGELOG = [
  {
    name: "Menghapus font inter",
  },
  {
    name: "Menambahkan logo",
  },
  {
    name: "Menambahkan dummy data table",
  },
  {
    name: "Menambahkan perfect scrollbar pada sidebar",
  },
  {
    name: "Menambahkan modal update app",
  },
];

const DATA_TYPE = {
  STRING: "string",
  UNDEFINED: "undefined",
};

const STATUS_CODE = {
  200: 200,
  400: 400,
  409: 409,
  401: 401,
  404: 404,
  422: 422,
  500: 500,
};

const USER_TYPE = {
  ADMIN: "superadmin",
  POPT: "popt",
  GUEST: "guest",
};

const INPUT_TYPE = {
  INPUT: "input",
  TEXT: "text",
  TEXTAREA: "textarea",
  SELECT: "select",
  PASSWORD: "password",
  SWITCH: "switch",
  PRICE: "price",
  SELECT_SYNC: "select_sync",
  SELECT_CREATABLE: "select_creatable",
  UPLOAD_IMAGE: "upload_image",
  TEXT_EDITOR: "text_editor",
  RADIO: "radio",
  COLOR: "color",
  YEAR: "year",
  DATE: "date",
  PHONE: "phone",
};

const DROPDOWN_TYPE_USER = [
  {
    id: 1,
    label: "SUPER ADMIN",
  },
  {
    id: 2,
    label: "ADMIN BAPPEDA",
  },
  {
    id: 3,
    label: "ADMIN OPD",
  },
];

const REACT_SELECT_CUSTOM_STYLES = {
  menu: (styles) => ({
    ...styles,
    borderRadius: 0,
    zIndex: 9999999,
  }),
  placeholder: (styles) => ({
    ...styles,
    color: "#8898a9",
    marginLeft: ".75rem",
  }),
  input: (styles) => ({
    ...styles,
    marginLeft: ".75rem",
    padding: ".35rem 0 .35rem 0",
    boxShadow: "none",
  }),
  option: (styles, { isSelected, isFocused }) => ({
    ...styles,
    backgroundColor: isSelected ? "#13A5A5" : isFocused ? "#13A5A5" : undefined,
    color: isFocused ? "#fff" : isSelected ? "#fff" : undefined,
  }),
  multiValue: (styles) => ({
    ...styles,
    width: "145px",
    backgroundColor: "#CDA2BE",
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    color: "white",
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: "white",
  }),
};

const DUMMY_CENTER = [-7.265605, 107.947314];

const LAYER_LEVEL = [
  {
    label: "KECAMATAN",
    value: "kecamatan",
  },
  {
    label: "DESA",
    value: "desa",
  },
];

const MUSIM_DATA = [
  {
    label: "KEMARAU",
    value: "kemarau",
  },
  {
    label: "PENGUJAN",
    value: "penghujan",
  },
];

const ALL_VALUES = {
  ALL: "all",
  LISTS: [
    {
      label: "SEMUA",
      value: "all",
    },
  ],
};

const STATUS_IMPORT = {
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
  FINISHED: "FINISHED",
  PROGRESS: "PROGRESS",
};

const USERS_ROLES = {
  POPT: "popt",
  ADMIN: "admin",
  GUEST: "guest",
  LISTS: [
    {
      label: "Popt",
      value: "popt",
    },
    {
      label: "Admin",
      value: "admin",
    },
    {
      label: "Guest",
      value: "guest",
    },
  ],
};

const PERIODE = [
  {
    label: "Periode 1",
    value: 1,
  },
  {
    label: "Periode 2",
    value: 2,
  },
];

const FILTER_DPI_GRAPH = [
  {
    label: "Luas Terdampak",
    value: "affected_area",
  },
  {
    label: "Luas Puso",
    value: "puso_area",
  },
  {
    label: "Rasio Puso",
    value: "puso_ratio",
  },
];

const PERANKINGAN = [
  {
    label: "5 Tertinggi",
    value: 5,
  },
  {
    label: "10 Tertinggi",
    value: 10,
  },
];

const SATUAN_OPTIONS = [
  {
    label: "%",
  },
  {
    label: "ekor/rumpun",
  },
];

const TOKEN_EXPIRED = {
  CODE: "token_not_valid",
  DETAIL: "Given token not valid for any token type",
};

const AUTH_NO_PROVIDED = "Authentication credentials were not provided.";

const TEXTEDITOR_CONFIG = {
  toolbar: {
    items: [
      "|",
      "undo",
      "redo",
      "removeFormat",
      "|",
      "heading",
      "|",
      "fontSize",
      "fontFamily",
      "fontColor",
      "fontBackgroundColor",
      "|",
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "code",
      "subscript",
      "superscript",
      "|",
      "bulletedList",
      "numberedList",
      "|",
      "alignment",
      "indent",
      "outdent",
      "|",
      "link",
      "insertTable",
      "specialCharacters",
      //       'imageUpload',
      "mediaEmbed",
      "htmlEmbed",
      "|",
      "highlight",
      "blockQuote",
      "horizontalLine",
      "pageBreak",
      "|",
      "restrictedEditingException",
    ],
  },
  blockToolbar: [
    "heading",
    "fontSize",
    "fontColor",
    "fontBackgroundColor",
    "alignment",
    "|",
    "bulletedList",
    "numberedList",
    "|",
    "blockQuote",
    //     'imageUpload'
  ],
  fontSize: {
    options: [
      8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 39,
      40, 42, 44, 46, 48, 50, 62, 64, 66, 68, 70, 72,
    ],
  },
  //   image: {
  //     toolbar: [
  //       'imageStyle:alignLeft',
  //       'imageStyle:full',
  //       'imageStyle:alignRight',
  //       '|',
  //       'imageTextAlternative'
  //     ],
  //     styles: ['full', 'side', 'alignLeft', 'alignCenter', 'alignRight']
  //   },
  table: {
    contentToolbar: [
      "tableColumn",
      "tableRow",
      "mergeTableCells",
      "tableProperties",
      "tableCellProperties",
    ],
  },
};

const GENDER = {
  1: "Laki - Laki",
  2: "Perempuan",
};

const STATUS_DPI = [
  {
    label: "Semua Status",
    value: "all",
  },
  {
    label: "Aman",
    value: "aman",
    colour: "#1a9641",
  },
  {
    label: "Rendah",
    value: "rendah",
    colour: "#c4e687",
  },
  {
    label: "Sedang",
    value: "sedang",
    colour: "#fec981",
  },
  {
    label: "Tinggi",
    value: "tinggi",
    colour: "#d7191c",
  },
];

const LEGEND_STATUS = {
  RENDAH: "#c4e687",
  AMAN: "#1a9641",
  SEDANG: "#fec981",
  TINGGI: "#d7191c",
};

const UNIQUE_RESPONSE = {
  EMAIL: "user with this email_address already exists.",
};

export {
  DUMMY_DATAS_TABLE,
  CHANGELOG,
  DATA_TYPE,
  STATUS_CODE,
  USER_TYPE,
  INPUT_TYPE,
  DROPDOWN_TYPE_USER,
  REACT_SELECT_CUSTOM_STYLES,
  DUMMY_CENTER,
  LAYER_LEVEL,
  MUSIM_DATA,
  ALL_VALUES,
  STATUS_IMPORT,
  USERS_ROLES,
  PERIODE,
  PERANKINGAN,
  SATUAN_OPTIONS,
  TOKEN_EXPIRED,
  AUTH_NO_PROVIDED,
  TEXTEDITOR_CONFIG,
  GENDER,
  STATUS_DPI,
  LEGEND_STATUS,
  FILTER_DPI_GRAPH,
  UNIQUE_RESPONSE,
};
