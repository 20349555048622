import { useRef, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useMap, GeoJSON } from "react-leaflet";
import { useDispatch, useSelector } from "react-redux";
import MarkerClusterGroup from "react-leaflet-markercluster";
import LoadingOverlay from "react-loading-overlay";
import {
  setActiveKeySidebar,
  setLayerInfo,
  setLoadingMap,
  setMetaMap,
  updateGeoJSONData,
} from "redux/map/action";
import { LEGEND_STATUS, STATUS_DPI } from "utils/constant";
import { request } from "utils/request";
import { replaceToFullPath } from "utils/helper";
import MapLayout from "layouts/map";
import PageTitle from "components/page-title";
import SidebarPetaDPI from "views/peta-dpi/sidebar";
import BottombarPetaDPI from "views/peta-dpi/bottombar";

const LayerDPIMap = () => {
  const {
    geojson: geoData,
    layerInfo,
    filterGeoJSON,
  } = useSelector((state) => state.map);

  const map = useMap();
  const layerRef = useRef(null);
  const dispatch = useDispatch();
  const [activeLayer, setActiveLayer] = useState(null);

  const handleEachFeature = (feature, layer) => {
    layer.on({
      mouseover: (e) => {
        let layer = e.target;

        if (feature.geometry.type === "Point") {
          layer.bindTooltip(filterGeoJSON?.tipe_nama).openTooltip();
        }

        layer.bindTooltip(feature.properties.nama).openTooltip();
      },
      click: (e) => {
        let layer = e.target;
        const zoomNow = map.getZoom();
        const flyToZoom = zoomNow > 12 ? zoomNow : 12;
        setActiveLayer(feature);
        dispatch(setLayerInfo(feature));
        dispatch(setActiveKeySidebar("information"));
        map.flyTo([layer.getCenter().lat, layer.getCenter().lng], flyToZoom);
      },
    });
  };

  const handleCreateStyles = (feature) => {
    if (JSON.stringify(activeLayer) === JSON.stringify(feature)) {
      return {
        weight: 2,
        color: "red",
        fillOpacity: 0.7,
        fillColor: LEGEND_STATUS[feature.properties.status?.toUpperCase()],
      };
    }

    return {
      weight: 1,
      color: "#000",
      fillOpacity: 0.7,
      fillColor: LEGEND_STATUS[feature.properties.status?.toUpperCase()],
    };
  };

  useEffect(() => {
    if (layerInfo) {
      async function handleRequestDPIKecamatan() {
        dispatch(setLoadingMap(true));
        try {
          const response = await request.get(
            `/api/dpi-kecamatan/map/${layerInfo?.id}/`
          );

          const responseURL = await response.data.url;

          fetch(replaceToFullPath(responseURL))
            .then((isRes) => isRes.json())
            .then((response) => dispatch(updateGeoJSONData(response.features)))
            .finally(() => dispatch(setLoadingMap(false)));
        } finally {
        }
      }

      handleRequestDPIKecamatan();
    }
  }, [layerInfo]);

  useEffect(() => {
    if (geoData && geoData?.features?.length > 0) {
      const centroidLayer = [
        layerRef?.current?.getBounds()?.getCenter()?.lat,
        layerRef?.current?.getBounds()?.getCenter()?.lng,
      ];

      const zoomNow = map.getZoom();

      const flyToZoom = zoomNow > 10 ? zoomNow : 10;

      map.flyTo(centroidLayer, flyToZoom);

      dispatch(setMetaMap({ legenda: STATUS_DPI }));
    }
  }, [geoData]);

  if (geoData) {
    return (
      <MarkerClusterGroup disableClusteringAtZoom>
        <GeoJSON
          ref={layerRef}
          data={geoData}
          key={uuidv4()}
          style={handleCreateStyles}
          onEachFeature={handleEachFeature}
        />
      </MarkerClusterGroup>
    );
  }

  return null;
};

const PetaDPI = () => {
  const { loading: loadingRequestMap } = useSelector((state) => state.map);

  return (
    <LoadingOverlay active={loadingRequestMap} spinner text="memuat layer...">
      <PageTitle title="Peta DPI" />

      <MapLayout
        withLegend
        sidebarChildren={<SidebarPetaDPI />}
        bottomBarChildren={<BottombarPetaDPI />}
        style={{
          width: "100%",
          height: "100vh",
          position: "fixed",
          top: "70px",
          right: 0,
        }}
      >
        <LayerDPIMap />
      </MapLayout>
    </LoadingOverlay>
  );
};

export default PetaDPI;
