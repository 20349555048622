import { useSelector } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import { ucFirst } from "utils/helper";
import { replaceToFullPath } from "utils/helper";
import ImageDefault from "assets/png/image-default.png";

const StackDetail = ({ title, description, colorDesc }) => {
  return (
    <div className="d-flex align-items-center gap-5 mb-3">
      <p style={{ width: "30%", fontSize: "13px" }}>{title}</p>
      <p>:</p>
      <p
        style={{
          color: colorDesc ?? "#676B74",
          fontSize: "13px",
          fontWeight: 600,
        }}
      >
        {description}
      </p>
    </div>
  );
};

const LayerInfo = () => {
  const { layerInfo } = useSelector((state) => state.map);

  if (!layerInfo) {
    return <div>silakan pilih layer untuk memuat informasi.</div>;
  }

  return (
    <PerfectScrollbar>
      {layerInfo?.geometry?.type === "Point" && (
        <img
          src={replaceToFullPath(layerInfo?.properties?.foto) ?? ImageDefault}
          alt="thumbnail"
          style={{
            width: "323px",
            height: "181px",
            borderRadius: "10px",
          }}
        />
      )}
      <div className="mt-2">
        <div className="p-3">
          <StackDetail
            title={layerInfo?.geometry?.type === "Point" ? "Desa" : "Kecamatan"}
            description={
              layerInfo?.geometry?.type === "Point"
                ? layerInfo?.properties?.desa_nama
                : layerInfo?.properties?.nama
            }
          />
        </div>

        <div
          className="p-3"
          style={{
            borderTop: "1px solid #E8E8E8",
            borderBottom: "1px solid #E8E8E8",
          }}
        >
          <StackDetail
            title="Luas Terdampak (LT)"
            description={layerInfo?.properties?.affected_area}
          />
        </div>

        <div
          className="p-3"
          style={{
            borderTop: "1px solid #E8E8E8",
            borderBottom: "1px solid #E8E8E8",
          }}
        >
          <StackDetail
            title="Luas Puso (LP)"
            description={layerInfo?.properties?.puso_area}
          />
        </div>

        <div
          className="p-3"
          style={{
            borderTop: "1px solid #E8E8E8",
            borderBottom: "1px solid #E8E8E8",
          }}
        >
          <StackDetail
            title="Luas Rasio Puso (LRP)"
            description={layerInfo?.properties?.puso_ratio}
          />
        </div>

        {layerInfo?.geometry?.type !== "Point" && (
          <div
            className="p-3"
            style={{
              borderTop: "1px solid #E8E8E8",
              borderBottom: "1px solid #E8E8E8",
            }}
          >
            <StackDetail
              title="Status"
              description={ucFirst(layerInfo?.properties?.status)}
            />
          </div>
        )}
      </div>
    </PerfectScrollbar>
  );
};

export default LayerInfo;
