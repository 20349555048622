import { useSelector } from "react-redux";
import { MapContainer, TileLayer } from "react-leaflet";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import TopbarMap from "./topbar";
import MapControl from "./map-control";

const MapLayout = ({
  children,
  sidebarChildren,
  bottomBarChildren,
  withLegend,
  legendType,
  ...rest
}) => {
  const { openBottomBar } = useSelector((state) => state.map);
  return (
    <>
      <TopbarMap />

      <div className="d-flex flex-column flex-lg-row h-lg-full">
        <nav
          id="sidebarmap"
          className="navbar show navbar-vertical h-lg-screen navbar-expand-lg px-0 py-3 navbar-light bg-white border-bottom border-bottom-lg-0 border-end-lg"
        >
          <PerfectScrollbar className="container-fluid">
            <button
              className="navbar-toggler ms-n2"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#sidebarMapCollapse"
              aria-controls="sidebarMapCollapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="sidebarMapCollapse">
              {sidebarChildren}

              <div className="mt-auto"></div>
            </div>
          </PerfectScrollbar>
        </nav>

        <div id="contentmap" className="flex-lg-1 overflow-y-lg-auto">
          <MapContainer
            zoom={4.5}
            preferCanvas
            zoomControl={false}
            center={[-0.789275, 113.921327]}
            {...rest}
          >
            <TileLayer
              url={"https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"}
              subdomains={["mt0", "mt1", "mt2", "mt3"]}
            />

            {children}

            <MapControl withLegend={withLegend} legendType={legendType} />
          </MapContainer>
        </div>

        <div>
          <div
            id="bottommap"
            style={{
              transition:
                ".5s visibility 0s linear 0.33s, opacity 0.33s linear",
              opacity: openBottomBar ? 1 : 0,
              transitionDelay: openBottomBar ? "0s" : "inherit",
              visibility: openBottomBar ? "visible" : "hidden",
            }}
          >
            {bottomBarChildren}
          </div>
        </div>

        {/* <OverlayTrigger
          placement="left"
          overlay={<Tooltip>{isBottomBarOpen ? "Tutup" : "Buka"}</Tooltip>}
        >
          <button
            className="btn btn-sm btn-neutral"
            onClick={() => setIsBottomBarOpen(!isBottomBarOpen)}
            style={{
              right: "0",
              position: "absolute",
              bottom: isBottomBarOpen ? "330px" : "0",
            }}
          >
            {isBottomBarOpen ? (
              <i className="bi bi-arrow-down" />
            ) : (
              <i className="bi bi-arrow-up" />
            )}
          </button>
        </OverlayTrigger> */}
      </div>
    </>
  );
};

MapLayout.propTypes = {
  children: PropTypes.node,
  sidebarChildren: PropTypes.node,
  rest: PropTypes.object,
  bottomBarChildren: PropTypes.node,
};

export default MapLayout;
