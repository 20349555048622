import { forwardRef } from "react";
import PropTypes from "prop-types";

const MDIInput = forwardRef(({ label, id, ...rest }, ref) => {
  return (
    <div className="form__group">
      <input id={id} ref={ref} className="form__field" {...rest} />
      <label for={id} className="form__label">
        {label}
      </label>
    </div>
  );
});

MDIInput.propTypes = {
  rest: PropTypes.object,
  label: PropTypes.string,
  id: PropTypes.string,
};

export default MDIInput;
