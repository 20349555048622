import { useState } from "react";
import { OverlayTrigger, Tooltip, Popover } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useMap } from "react-leaflet";
import { v4 as uuidv4 } from "uuid";
import L from "leaflet";
import { DUMMY_CENTER } from "utils/constant";
import LeafletControl from "components/map/control";
import PrintModal from "./print-modal";

const MapControl = ({ withLegend, legendType }) => {
  const { meta: metaMaps, url: mapURL } = useSelector((state) => state.map);

  const map = useMap();

  const handleZoomIn = () => map.zoomIn();
  const handleZoomOut = () => map.zoomOut();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleHome = () =>
    map.flyTo(new L.LatLng(DUMMY_CENTER[0], DUMMY_CENTER[1]), 15);

  return (
    <>
      <LeafletControl position={"topright"}>
        <div className="d-flex leaflet-custom-control flex-column gap-3">
          <div className="d-flex flex-column">
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip>Perbesar</Tooltip>}
            >
              <button
                type="button"
                onClick={handleZoomIn}
                className="btn-map-control"
                // style={{ left: isShow ? "388px" : "-12px" }}
              >
                <i className="bi bi-plus-lg text-md" />
              </button>
            </OverlayTrigger>

            <OverlayTrigger
              placement="left"
              overlay={<Tooltip>Kembali ke titik awal</Tooltip>}
            >
              <button
                type="button"
                onClick={handleHome}
                className="btn-map-control"
                // style={{ left: isShow ? "388px" : "-12px" }}
              >
                <i className="bi bi-house-door text-md" />
              </button>
            </OverlayTrigger>

            <OverlayTrigger
              placement="left"
              overlay={<Tooltip>Perkecil</Tooltip>}
            >
              <button
                type="button"
                onClick={handleZoomOut}
                className="btn-map-control"
                style={{
                  // left: isShow ? "388px" : "-12px",
                  borderTop: "1px solid #D3D3D3 !important",
                }}
              >
                <i className="bi bi-dash-lg text-md" />
              </button>
            </OverlayTrigger>
          </div>
        </div>
      </LeafletControl>

      <LeafletControl position={"topright"}>
        <OverlayTrigger placement="left" overlay={<Tooltip>Unduh</Tooltip>}>
          <button
            type="button"
            disabled={!mapURL}
            onClick={() => setIsModalOpen(true)}
            className="btn-map-control"
            style={{
              // left: isShow ? "388px" : "-12px",
              cursor: mapURL ? "pointer" : "not-allowed",
              borderTop: "1px solid #D3D3D3 !important",
            }}
          >
            <i className="bi bi-download text-md" />
          </button>
        </OverlayTrigger>
      </LeafletControl>

      {withLegend && (
        <LeafletControl position={"topright"}>
          <OverlayTrigger
            placement="left"
            trigger="click"
            overlay={
              <Popover>
                <Popover.Header>Legenda</Popover.Header>
                <Popover.Body style={{ width: "300px" }}>
                  <div className="vstack gap-2">
                    {metaMaps?.legenda
                      ?.filter((legend) => legend.value !== "all")
                      ?.map((legend) => (
                        <div
                          className="d-flex align-items-center gap-3"
                          key={uuidv4()}
                        >
                          <div
                            style={{
                              width: "50px",
                              height: "10px",
                              background: legend.colour,
                            }}
                          />
                          {legend.label} {legendType}
                        </div>
                      ))}
                  </div>
                </Popover.Body>
              </Popover>
            }
          >
            <button className="btn-map-control">
              <i className="bi bi-info" style={{ fontSize: "1.1rem" }} />
            </button>
          </OverlayTrigger>
        </LeafletControl>
      )}

      <PrintModal
        isModalOpen={isModalOpen}
        onCloseModal={() => setIsModalOpen(false)}
      />
    </>
  );
};

export default MapControl;
