import { format } from "date-fns";
import { Base64 } from "js-base64";
import { USER_TYPE } from "./constant";

const replaceAll = (text, replaceFrom, replaceAfter) => {
  const regex = new RegExp(replaceFrom, "g");
  const results = text?.replace(regex, replaceAfter);

  return results;
};

const findActiveMenuInRoutes = (match, path, childs, pathname) => {
  if (match) {
    const isHaveChild = childs?.length > 0;

    if (isHaveChild) {
      const results = childs.some((child) => child.path === pathname);

      return results;
    }

    return path === match.pathname;
  }

  return false;
};

const replaceRoleName = (userType) => {
  switch (userType) {
    case USER_TYPE.SUPER_USER:
      return "Super Admin";
    case USER_TYPE.ADMIN_BAPPEDA:
      return "Admin Bappeda";
    default:
      return "Admin OPD";
  }
};

const base64Validation = (base64) =>
  base64.replace(/^data:image.+;base64,/, "");

const toRupiah = (angka) => {
  const rupiah = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  }).format(angka || 0);
  return `${rupiah} ,-`;
};

const formatArrayInTable = (arr) => (
  <td className="d-flex flex-column gap-3">
    {arr?.map((elem) => (
      <>
        <p>
          {elem?.nama}, {elem?.nilai}
        </p>
      </>
    ))}
  </td>
);

const getYears = (startYear = 1980) => {
  let currentYear = new Date().getFullYear();

  let years = [];

  while (startYear <= currentYear) {
    years.push(startYear++);
  }

  return years.reverse();
};

const formatDate = (date, formatter) => {
  let pattern = /(\d{4})\-(\d{2})\-(\d{2})/;
  const defaultDate = date?.replace(pattern, "$2/$3/$1");

  const results = format(new Date(defaultDate), formatter);

  return results;
};

const replaceToFullPath = (relativePath) =>
  `${process.env.REACT_APP_API_URL}${relativePath}`;

const convertToLatLng = (latlng) => {
  const result = parseFloat(latlng.split(",")[0]);

  return result;
};

const rupiahToInt = (rupiah) => {
  const int = replaceAll(replaceAll(String(rupiah), "Rp", ""), ",", "");

  return parseInt(int);
};

const base64Formatter = (str) => `data:image/png;base64,${str}`;

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>
      resolve(reader.result.replace(/^data:image.+;base64,/, ""));
    reader.onerror = (error) => reject(error);
  });

const isFile = (input) => {
  if ("File" in window && input instanceof File) return true;
  else return false;
};

const featureToGeometryCollection = (featureCollection) => {
  return {
    type: "GeometryCollection",
    geometries: [featureCollection?.geometry],
  };
};

const getNumberList = (index, page = 1, perPage = 5) => {
  return index + 1 + (parseInt(page ?? 1) - 1) * parseInt(perPage ?? 5);
};

const nullChecker = (cell) => (!cell ? "-" : cell);

const ucFirst = (t) => {
  if (t) {
    const up = String(t).replace(/(^|\s)[A-Za-zÀ-ÖØ-öø-ÿ]/g, (c) =>
      c.toUpperCase()
    );

    const result = replaceAll(up, "_", " ");

    return result;
  }

  return "-";
};

const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = Base64.atob(base64Validation(b64Data));
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export {
  replaceAll,
  findActiveMenuInRoutes,
  replaceRoleName,
  toBase64,
  base64Validation,
  toRupiah,
  formatArrayInTable,
  getYears,
  formatDate,
  replaceToFullPath,
  convertToLatLng,
  rupiahToInt,
  base64Formatter,
  isFile,
  featureToGeometryCollection,
  getNumberList,
  nullChecker,
  ucFirst,
  b64toBlob,
};
