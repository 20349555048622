import { toast } from "react-hot-toast";
import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { getAuthToken, getIsUserKeepLogin, setToken } from "./auth";
import { AUTH_NO_PROVIDED, STATUS_CODE, TOKEN_EXPIRED } from "./constant";

export const request = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 100000,
});

request.interceptors.request.use((config) => {
  if (!config.headers) {
    config.headers = {};
  }

  const token = getAuthToken();

  if (token) {
    config.headers["Authorization"] = `Bearer ${token?.access}`;
    config.headers["Content-Type"] = "application/json";
  }

  return config;
});

request.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    const errorResponse = err.response.data;
    const errorStatusCode = err.response.status;

    const isInternalError = [
      STATUS_CODE[400],
      STATUS_CODE[422],
      STATUS_CODE[409],
      STATUS_CODE[401],
      STATUS_CODE[404],
    ];

    if (isInternalError.includes(errorStatusCode)) {
      if (errorResponse?.detail) {
        if (
          errorResponse?.code !== TOKEN_EXPIRED.CODE &&
          errorResponse?.detail !== TOKEN_EXPIRED.DETAIL &&
          errorResponse?.detail !== AUTH_NO_PROVIDED
        ) {
          toast.error(errorResponse?.detail, {
            duration: 9000,
          });
        }
      }

      if (typeof errorResponse?.[0] === "string") {
        toast.error(errorResponse?.[0]);
      }
    }

    if (errorStatusCode === STATUS_CODE[500]) {
      toast.error("Terjadi kesalahan dari internal, silakan coba lagi nanti !");
    }

    return Promise.reject(err);
  }
);

const handleLogoutUser = () => {
  setToken(null);
  // window.location.replace("/login");
};

const refreshAuthLogic = (failedRequest) =>
  getIsUserKeepLogin()
    ? request
        .post("/api/token/refresh/", {
          refresh: getAuthToken().refresh,
        })
        .then((tokenResponse) => {
          const newToken = {
            access: tokenResponse.data.access,
            refresh: getAuthToken()?.refresh,
          };

          setToken(newToken);
          failedRequest.response.config.headers[
            "Authorization"
          ] = `Bearer ${newToken}`;

          return Promise.resolve();
        })
    : handleLogoutUser();

createAuthRefreshInterceptor(request, refreshAuthLogic);
