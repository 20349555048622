import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";
import Charts from "react-apexcharts";
import { request } from "utils/request";
import { FILTER_DPI_GRAPH } from "utils/constant";
import NoDataIlustration from "assets/png/no-data.png";

const BottombarPetaDPI = () => {
  const [chartData, setChartData] = useState([]);
  const [, setLoadingChartData] = useState(false);
  const [rankingPick, setRankingPick] = useState("puso_ratio");

  const { filterGeoJSON } = useSelector((state) => state.map);

  const handleRequestGraphChart = async (rankingPickParam) => {
    setLoadingChartData(true);
    try {
      const response = await request.get(
        `/api/dpi-kecamatan/?ordering=-${rankingPickParam}&per_page=5`
      );

      setChartData(response.data.data.reverse());
    } finally {
      setLoadingChartData(false);
    }
  };

  useEffect(() => {
    if (filterGeoJSON && rankingPick) {
      handleRequestGraphChart(rankingPick);
    }
  }, [filterGeoJSON, rankingPick]);

  const BarOptions = {
    chart: {
      stacked: false,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      shadow: {
        enabled: false,
      },
      offsetX: 0,
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
    },
    plotOptions: {
      bar: {
        distributed: true,
        // columnWidth: "93px",
        borderRadius: 2,
      },
    },
    colors: ["#00BABA", "#FFA63D", "#00CB84", "#FFA63D", "#FF5252", "#FFA63D"],
    stroke: {
      colors: ["transparent"],
      width: 4,
      curve: "smooth",
    },
    markers: {
      size: 0,
    },
    xaxis: {
      categories: chartData?.map((data) => `Kec ${data?.kecamatan_data?.nama}`),
      labels: {
        // rotate: 0,
        style: {
          colors: "#333",
        },
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
    legend: {
      show: false,
    },
    grid: {
      show: false,
    },
    dataLabels: {
      // enabled: false,
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return y.toFixed(0);
          }
          return y;
        },
      },
    },
  };

  return (
    <div style={{ borderLeftWith: "1px" }}>
      {filterGeoJSON ? (
        <>
          <div className="p-3">
            <p className="text-center">
              Daftar 5 Ranking Tertinggi Tingkat Keparahan DPI Se-Kabupaten
            </p>
          </div>

          <div className="row">
            <div className="col border">
              <div className="p-3">
                <div className="d-flex gap-3 align-items-center justify-content-start">
                  <div className="form-group">
                    <label className="form-label" htmlFor="select_klts">
                      Tampilkan Perangkingan
                    </label>

                    <select
                      className="form-select"
                      value={rankingPick}
                      onChange={(e) => setRankingPick(e.target.value)}
                    >
                      {FILTER_DPI_GRAPH.map((rank) => (
                        <option value={rank.value} key={uuidv4()}>
                          {rank.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="d-flex justify-content-center align-items-center mx-auto">
                  <Charts
                    type="bar"
                    width="1000px"
                    height="180px"
                    options={BarOptions}
                    series={[
                      {
                        data: chartData?.map((data) =>
                          data[rankingPick]?.toFixed(2)
                        ),
                        name: "nilai",
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="d-flex flex-column gap-16 py-16 align-items-center justify-content-center">
          <img width="8%" src={NoDataIlustration} alt="nodata" />

          <p className="text-center text-sm text-dark">
            Opps, result not found.
          </p>
        </div>
      )}
    </div>
  );
};

export default BottombarPetaDPI;
