import {
  SET_FILTER_GEOJSON,
  SET_GEOJSON_DATA,
  SET_LAYER_INFO,
  SET_LOADING_MAP,
  SET_META_MAP,
  SET_ACTIVE_KEY_SIDEBAR,
  SET_OPEN_BOTTOM_BAR,
  UPDATE_GEOJSON_DATA,
  SET_MAP_URL,
} from "./type";

const INITIAL_REDUCER = {
  url: null,
  meta: null,
  geojson: null,
  loading: false,
  layerInfo: null,
  filterGeoJSON: null,
  openBottomBar: false,
  activeKeyTabSidebar: "layer",
};

const mapReducer = (state = INITIAL_REDUCER, action) => {
  switch (action.type) {
    case SET_FILTER_GEOJSON:
      return { ...state, filterGeoJSON: action.payload };
    case SET_META_MAP:
      return { ...state, meta: action.payload };
    case SET_GEOJSON_DATA:
      return { ...state, geojson: action.payload };
    case SET_LOADING_MAP:
      return { ...state, loading: action.payload };
    case SET_LAYER_INFO:
      return { ...state, layerInfo: action.payload };
    case SET_ACTIVE_KEY_SIDEBAR:
      return { ...state, activeKeyTabSidebar: action.payload };
    case SET_OPEN_BOTTOM_BAR:
      return { ...state, openBottomBar: action.payload };
    case UPDATE_GEOJSON_DATA:
      return {
        ...state,
        geojson: {
          ...state.geojson,
          features: [...state.geojson.features, ...action.payload],
        },
      };
    case SET_MAP_URL:
      return { ...state, url: action.payload };
    default:
      return state;
  }
};

export default mapReducer;
