import {
  SET_ERRORS,
  SET_MENU,
  SET_TRIGGER_TABLE,
  SET_FROM_LOGIN,
  SET_LOADING,
  SET_TABLE_DATA,
} from "./type";

export const setMenu = (menu) => ({
  type: SET_MENU,
  payload: menu,
});

export const setErrors = (errors) => ({
  type: SET_ERRORS,
  payload: errors,
});

export const setTriggerTable = (trigger) => ({
  type: SET_TRIGGER_TABLE,
  payload: trigger,
});

export const setFromLogin = (fromLogin) => ({
  type: SET_FROM_LOGIN,
  payload: fromLogin,
});

export const setLoadingApp = (loading) => ({
  type: SET_LOADING,
  payload: loading,
});

export const setTableData = (tableData) => ({
  type: SET_TABLE_DATA,
  payload: tableData,
});
