import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Avatar from "react-avatar";
import LogoSiram from "assets/svg/siram.svg";
import KabGarut from "assets/png/logo/dinas.png";
import { ucFirst } from "utils/helper";

const TopbarMap = () => {
  const { user } = useSelector((state) => state.auth);
  return (
    <nav
      style={{ width: "100%", zIndex: "999" }}
      className="navbar navbar-light position-lg-fixed top-lg-0 d-none d-lg-block flex-none bg-white border-bottom px-0 py-3"
    >
      <div className="container-fluid">
        <Link to="/">
          <div className="d-flex align-items-center gap-5">
            <img alt="kabgarut" src={KabGarut} className="logo-left" />
            <div className="logo-divider" />
            <img alt="siram" src={LogoSiram} className="logo-right" />
          </div>
        </Link>

        <div className="navbar-user d-none d-sm-block ms-auto">
          <div className="hstack gap-3 ms-4">
            {/* <Notification /> */}
            <div className="dropdown">
              <a
                className="d-flex align-items-center"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="false"
                aria-expanded="false"
              >
                <div>
                  <div className="avatar avatar-sm bg-warning rounded-circle text-white">
                    <Avatar
                      src={user?.photo}
                      alt={user?.username}
                      name={user?.username}
                      size="40px"
                      color="#13A5A5"
                      className="avatar avatar-sm rounded-circle"
                    />
                  </div>
                </div>
                <div className="d-none d-sm-block ms-3">
                  <span className="h6">{ucFirst(user?.username)}</span>
                </div>
                <div className="d-none d-md-block ms-md-2">
                  <i className="bi bi-chevron-down text-muted text-xs"></i>
                </div>
              </a>
              <div
                className="dropdown-menu dropdown-menu-end"
                style={{ zIndex: "999999999 !important" }}
              >
                <div className="dropdown-header">
                  <span className="d-block text-sm text-muted mb-1">
                    Signed in as
                  </span>
                  <span className="d-block text-heading font-semibold">
                    {user?.role}
                  </span>
                </div>
                <div className="dropdown-divider"></div>
                <Link className="dropdown-item" to="/profile">
                  <i className="bi bi-pencil me-3"></i>Edit profile
                </Link>
                <div className="dropdown-divider"></div>
                <Link className="dropdown-item" to="/logout">
                  <i className="bi bi-box-arrow-left me-3"></i>Logout
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default TopbarMap;
