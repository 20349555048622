import PetaDPI from "pages/map/peta-dpi";
import { lazy } from "react";
import { Navigate } from "react-router-dom";
import { USER_TYPE } from "utils/constant";

/**
 * PRIVATE ROUTE
 */

const PrivateRoute = lazy(() => import("./private"));

/**
 * AUTH
 *
 */

const LoginPages = lazy(() => import("pages/auth/login"));
const LogoutPages = lazy(() => import("pages/auth/logout"));
const ResetPasswordPages = lazy(() => import("pages/auth/reset-password"));
const ForgotPasswordPages = lazy(() => import("pages/auth/forgot-password"));

/**
 * PRIVATE PAGES
 *
 */

const DashboardPages = lazy(() => import("pages/dashboard"));

/**
 * PROFILE
 */

const ProfilePages = lazy(() => import("pages/profile"));

/**
 * SETTINGS
 */

// const PeriodeUpload = lazy(() => import("pages/settings/periode-upload"));

/**
 * MASTER DATA
 */

// DPI
const MasterDPI = lazy(() => import("pages/master-data/dpi/list"));
const CreateDPI = lazy(() => import("pages/master-data/dpi/create"));
const UpdateDPI = lazy(() => import("pages/master-data/dpi/update"));

// FASE
const MasterFase = lazy(() => import("pages/master-data/fase/list"));
const CreateFase = lazy(() => import("pages/master-data/fase/create"));
const UpdateFase = lazy(() => import("pages/master-data/fase/update"));

// KOMODITAS
const MasterKomoditas = lazy(() => import("pages/master-data/komoditas/list"));
const CreateKomoditas = lazy(() =>
  import("pages/master-data/komoditas/create")
);
const UpdateKomoditas = lazy(() =>
  import("pages/master-data/komoditas/update")
);

//OPT

const MasterOPT = lazy(() => import("pages/master-data/opt/list"));
const CreateOPT = lazy(() => import("pages/master-data/opt/create"));
const UpdateOPT = lazy(() => import("pages/master-data/opt/update"));
const ListFaseMitigasi = lazy(() => import("pages/master-data/opt/list-fase"));

//MITIGASI
const ListMitigasi = lazy(() => import("pages/master-data/opt/mitigasi/list"));
const CreateMitigasi = lazy(() =>
  import("pages/master-data/opt/mitigasi/create")
);
const UpdateMitigasi = lazy(() =>
  import("pages/master-data/opt/mitigasi/update")
);

// VARIETAS
const MasterVarietas = lazy(() => import("pages/master-data/varietas/list"));
const CreateVarietas = lazy(() => import("pages/master-data/varietas/create"));
const UpdateVarietas = lazy(() => import("pages/master-data/varietas/update"));

/**
 * OPT MUSIMAN
 */

const ListOPTMusiman = lazy(() => import("pages/opt-musiman/list"));

/**
 * OPT REALTIME
 */

const ListOPTRealTime = lazy(() => import("pages/opt-realtime/list"));
const DetailOPTRealTime = lazy(() => import("pages/opt-realtime/detail"));

// BREAKDOWN

const ListBreakdownKecamatan = lazy(() =>
  import("pages/opt-musiman/breakdown/kecamatan")
);

const ListBreakdownDesa = lazy(() =>
  import("pages/opt-musiman/breakdown/desa")
);

/**
 * SETTINGS
 */

const UserManagementList = lazy(() =>
  import("pages/settings/user-management/list")
);

const UserManagementAdd = lazy(() =>
  import("pages/settings/user-management/create")
);

const UserManagementUpdate = lazy(() =>
  import("pages/settings/user-management/update")
);

/**
 * DPI
 */

const ListDPIPages = lazy(() => import("pages/dpi/list"));
const EventDPIPages = lazy(() => import("pages/dpi/event"));
const HistoryDPIPages = lazy(() => import("pages/dpi/history"));
const StatistikDPIPages = lazy(() => import("pages/dpi/statistik"));

/**
 * MAP
 */

// DASHBOARD MAP

const PetaMusiman = lazy(() => import("pages/map/dashboard-peta"));
const PetaRealTime = lazy(() => import("pages/map/peta-realtime"));

/**
 * ERROR PAGES
 *
 */

const NotFoundPage = lazy(() => import("pages/errors/404"));

export const routes = [
  {
    exact: true,
    path: "*",
    element: <NotFoundPage />,
  },
  {
    exact: true,
    path: "/",
    element: <Navigate to="/dashboard" />,
  },
  {
    exact: true,
    path: "/login",
    element: <LoginPages />,
  },
  {
    exact: true,
    path: "/logout",
    userType: [USER_TYPE.ADMIN, USER_TYPE.GUEST, USER_TYPE.POPT],
    element: (
      <PrivateRoute
        userType={[USER_TYPE.ADMIN, USER_TYPE.GUEST, USER_TYPE.POPT]}
        withLayout={false}
      >
        <LogoutPages />
      </PrivateRoute>
    ),
  },
  {
    exact: true,
    path: "/forgot-password",
    element: <ForgotPasswordPages />,
  },
  {
    exact: true,
    path: "/reset-password",
    element: <ResetPasswordPages />,
  },
  {
    exact: true,
    path: "/profile",
    userType: [USER_TYPE.ADMIN, USER_TYPE.GUEST, USER_TYPE.POPT],
    element: (
      <PrivateRoute
        userType={[USER_TYPE.ADMIN, USER_TYPE.GUEST, USER_TYPE.POPT]}
      >
        <ProfilePages />
      </PrivateRoute>
    ),
  },
  {
    name: "Dashboard",
    exact: true,
    path: "/dashboard",
    icon: "bi bi-briefcase",
    userType: [USER_TYPE.ADMIN, USER_TYPE.GUEST, USER_TYPE.POPT],
    element: (
      <PrivateRoute
        userType={[USER_TYPE.ADMIN, USER_TYPE.GUEST, USER_TYPE.POPT]}
      >
        <DashboardPages />
      </PrivateRoute>
    ),
  },
  {
    name: "Data Master",
    icon: "bi bi-columns-gap",
    userType: [USER_TYPE.ADMIN],
    children: [
      {
        name: "Komoditas",
        exact: true,
        userType: [USER_TYPE.ADMIN],
        children: [
          {
            name: "Daftar Komoditas",
            exact: true,
            userType: [USER_TYPE.ADMIN],
            path: "/master-data-komoditas/daftar",
            element: (
              <PrivateRoute userType={[USER_TYPE.ADMIN]}>
                <MasterKomoditas />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            name: "Tambah Komoditas",
            userType: [USER_TYPE.ADMIN],
            path: "/master-data-komoditas/tambah",
            element: (
              <PrivateRoute userType={[USER_TYPE.ADMIN]}>
                <CreateKomoditas />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            userType: [USER_TYPE.ADMIN],
            path: "/master-data-komoditas/ubah/:id",
            element: (
              <PrivateRoute userType={[USER_TYPE.ADMIN]}>
                <UpdateKomoditas />
              </PrivateRoute>
            ),
          },
        ],
      },
      {
        name: "Varietas",
        exact: true,
        userType: [USER_TYPE.ADMIN],
        children: [
          {
            name: "Daftar Varietas",
            exact: true,
            userType: [USER_TYPE.ADMIN],
            path: "/master-data-varietas/daftar",
            element: (
              <PrivateRoute userType={[USER_TYPE.ADMIN]}>
                <MasterVarietas />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            name: "Tambah Varietas",
            userType: [USER_TYPE.ADMIN],
            path: "/master-data-varietas/tambah",
            element: (
              <PrivateRoute userType={[USER_TYPE.ADMIN]}>
                <CreateVarietas />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            userType: [USER_TYPE.ADMIN],
            path: "/master-data-varietas/ubah/:id",
            element: (
              <PrivateRoute userType={[USER_TYPE.ADMIN]}>
                <UpdateVarietas />
              </PrivateRoute>
            ),
          },
        ],
      },
      {
        name: "Fase",
        exact: true,
        userType: [USER_TYPE.ADMIN],
        children: [
          {
            name: "Daftar Fase",
            exact: true,
            userType: [USER_TYPE.ADMIN],
            path: "/master-data-fase/daftar",
            element: (
              <PrivateRoute userType={[USER_TYPE.ADMIN]}>
                <MasterFase />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            name: "Tambah Fase",
            userType: [USER_TYPE.ADMIN],
            path: "/master-data-fase/tambah",
            element: (
              <PrivateRoute userType={[USER_TYPE.ADMIN]}>
                <CreateFase />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            userType: [USER_TYPE.ADMIN],
            path: "/master-data-fase/ubah/:id",
            element: (
              <PrivateRoute userType={[USER_TYPE.ADMIN]}>
                <UpdateFase />
              </PrivateRoute>
            ),
          },
        ],
      },
      {
        name: "OPT",
        exact: true,
        userType: [USER_TYPE.ADMIN],
        children: [
          {
            name: "Daftar OPT",
            exact: true,
            userType: [USER_TYPE.ADMIN],
            path: "/master-data-opt/daftar",
            element: (
              <PrivateRoute userType={[USER_TYPE.ADMIN]}>
                <MasterOPT />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            name: "Tambah OPT",
            userType: [USER_TYPE.ADMIN],
            path: "/master-data-opt/tambah",
            element: (
              <PrivateRoute userType={[USER_TYPE.ADMIN]}>
                <CreateOPT />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            userType: [USER_TYPE.ADMIN],
            path: "/master-data-opt/ubah/:id",
            element: (
              <PrivateRoute userType={[USER_TYPE.ADMIN]}>
                <UpdateOPT />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            userType: [USER_TYPE.ADMIN],
            path: "/master-data-opt/fase-mitigasi/:id_opt/:nama_opt",
            element: (
              <PrivateRoute userType={[USER_TYPE.ADMIN]}>
                <ListFaseMitigasi />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            userType: [USER_TYPE.ADMIN],
            path: "/master-data-mitigasi/:id_opt/:id_fase",
            element: (
              <PrivateRoute userType={[USER_TYPE.ADMIN]}>
                <ListMitigasi />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            userType: [USER_TYPE.ADMIN],
            path: "/master-data-mitigasi/tambah/:id_opt/:id_fase",
            element: (
              <PrivateRoute userType={[USER_TYPE.ADMIN]}>
                <CreateMitigasi />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            userType: [USER_TYPE.ADMIN],
            path: "/master-data-mitigasi/ubah/:id",
            element: (
              <PrivateRoute userType={[USER_TYPE.ADMIN]}>
                <UpdateMitigasi />
              </PrivateRoute>
            ),
          },
        ],
      },
      {
        name: "DPI",
        userType: [USER_TYPE.ADMIN],
        exact: true,
        children: [
          {
            name: "Daftar DPI",
            exact: true,
            userType: [USER_TYPE.ADMIN],
            path: "/master-data-dpi/daftar",
            element: (
              <PrivateRoute userType={[USER_TYPE.ADMIN]}>
                <MasterDPI />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            name: "Tambah DPI",
            userType: [USER_TYPE.ADMIN],
            path: "/master-data-dpi/tambah",
            element: (
              <PrivateRoute userType={[USER_TYPE.ADMIN]}>
                <CreateDPI />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            userType: [USER_TYPE.ADMIN],
            path: "/master-data-dpi/ubah/:id",
            element: (
              <PrivateRoute userType={[USER_TYPE.ADMIN]}>
                <UpdateDPI />
              </PrivateRoute>
            ),
          },
        ],
      },
    ],
  },
  {
    name: "OPT Musiman",
    exact: true,
    path: "/opt-musiman",
    icon: "bi bi-clouds",
    userType: [USER_TYPE.ADMIN, USER_TYPE.GUEST, USER_TYPE.POPT],
    element: (
      <PrivateRoute
        userType={[USER_TYPE.ADMIN, USER_TYPE.GUEST, USER_TYPE.POPT]}
      >
        <ListOPTMusiman />
      </PrivateRoute>
    ),
  },
  {
    path: "/dpi/statistik/:id/:year",
    userType: [USER_TYPE.ADMIN, USER_TYPE.GUEST, USER_TYPE.POPT],
    element: (
      <PrivateRoute
        userType={[USER_TYPE.ADMIN, USER_TYPE.GUEST, USER_TYPE.POPT]}
      >
        <StatistikDPIPages />
      </PrivateRoute>
    ),
  },
  {
    path: "/dpi/:dpikecamatanid/history",
    userType: [USER_TYPE.ADMIN, USER_TYPE.GUEST, USER_TYPE.POPT],
    element: (
      <PrivateRoute
        userType={[USER_TYPE.ADMIN, USER_TYPE.GUEST, USER_TYPE.POPT]}
      >
        <HistoryDPIPages />
      </PrivateRoute>
    ),
  },
  {
    path: "/dpi/:kecamatankode/:kecamatanid/:dpikecamatanid/:dpiyear/event",
    userType: [USER_TYPE.ADMIN, USER_TYPE.GUEST, USER_TYPE.POPT],
    element: (
      <PrivateRoute
        userType={[USER_TYPE.ADMIN, USER_TYPE.GUEST, USER_TYPE.POPT]}
      >
        <EventDPIPages />
      </PrivateRoute>
    ),
  },
  {
    name: "OPT Real Time",
    exact: true,
    path: "/opt-realtime",
    icon: "bi bi-hourglass-split",
    userType: [USER_TYPE.ADMIN, USER_TYPE.GUEST, USER_TYPE.POPT],
    element: (
      <PrivateRoute
        userType={[USER_TYPE.ADMIN, USER_TYPE.GUEST, USER_TYPE.POPT]}
      >
        <ListOPTRealTime />
      </PrivateRoute>
    ),
  },
  {
    name: "DPI",
    exact: true,
    path: "/dpi",
    icon: "bi bi-flower2",
    userType: [USER_TYPE.ADMIN, USER_TYPE.GUEST, USER_TYPE.POPT],
    element: (
      <PrivateRoute
        userType={[USER_TYPE.ADMIN, USER_TYPE.GUEST, USER_TYPE.POPT]}
      >
        <ListDPIPages />
      </PrivateRoute>
    ),
  },
  {
    path: "/opt-realtime/detail/:id",
    userType: [USER_TYPE.ADMIN, USER_TYPE.GUEST, USER_TYPE.POPT],
    element: (
      <PrivateRoute
        userType={[USER_TYPE.ADMIN, USER_TYPE.GUEST, USER_TYPE.POPT]}
      >
        <DetailOPTRealTime />
      </PrivateRoute>
    ),
  },
  {
    exact: true,
    path: "/opt-musiman/breakdown-kecamatan/:periode/:opt_id",
    userType: [USER_TYPE.ADMIN, USER_TYPE.GUEST, USER_TYPE.POPT],
    element: (
      <PrivateRoute
        userType={[USER_TYPE.ADMIN, USER_TYPE.GUEST, USER_TYPE.POPT]}
      >
        <ListBreakdownKecamatan />
      </PrivateRoute>
    ),
  },
  {
    exact: true,
    path: "/opt-musiman/breakdown-desa/:periode/:kec_kode/:kec_id/:opt_id",
    userType: [USER_TYPE.ADMIN, USER_TYPE.GUEST, USER_TYPE.POPT],
    element: (
      <PrivateRoute
        userType={[USER_TYPE.ADMIN, USER_TYPE.GUEST, USER_TYPE.POPT]}
      >
        <ListBreakdownDesa />
      </PrivateRoute>
    ),
  },
  {
    name: "Pengaturan",
    icon: "bi bi-terminal",
    exact: true,
    userType: [USER_TYPE.ADMIN],
    children: [
      {
        name: "User Management",
        exact: true,
        userType: [USER_TYPE.ADMIN],
        path: "/pengaturan-user-management/daftar",
        element: (
          <PrivateRoute userType={[USER_TYPE.ADMIN]}>
            <UserManagementList />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        userType: [USER_TYPE.ADMIN],
        path: "/pengaturan-user-management/tambah",
        element: (
          <PrivateRoute userType={[USER_TYPE.ADMIN]}>
            <UserManagementAdd />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        userType: [USER_TYPE.ADMIN],
        path: "/pengaturan-user-management/ubah/:id",
        element: (
          <PrivateRoute userType={[USER_TYPE.ADMIN]}>
            <UserManagementUpdate />
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    path: "/peta-musiman",
    userType: [USER_TYPE.ADMIN, USER_TYPE.GUEST, USER_TYPE.POPT],
    element: (
      <PrivateRoute
        withLayout={false}
        userType={[USER_TYPE.ADMIN, USER_TYPE.GUEST, USER_TYPE.POPT]}
      >
        <PetaMusiman />
      </PrivateRoute>
    ),
  },
  {
    path: "/peta-realtime",
    userType: [USER_TYPE.ADMIN, USER_TYPE.GUEST, USER_TYPE.POPT],
    element: (
      <PrivateRoute
        withLayout={false}
        userType={[USER_TYPE.ADMIN, USER_TYPE.GUEST, USER_TYPE.POPT]}
      >
        <PetaRealTime />
      </PrivateRoute>
    ),
  },
  {
    path: "/peta-dpi",
    userType: [USER_TYPE.ADMIN, USER_TYPE.GUEST, USER_TYPE.POPT],
    element: (
      <PrivateRoute
        withLayout={false}
        userType={[USER_TYPE.ADMIN, USER_TYPE.GUEST, USER_TYPE.POPT]}
      >
        <PetaDPI />
      </PrivateRoute>
    ),
  },
];
