import { useDispatch, useSelector } from "react-redux";
import { Tabs, Tab, Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
  setGeoJSONData,
  setFilterGeoJSON,
  setLoadingMap,
  setOpenBottomBar,
  setActiveKeySidebar,
  setMapURL,
} from "redux/map/action";
import { STATUS_DPI } from "utils/constant";
import { getYears, replaceToFullPath, ucFirst } from "utils/helper";
import { request } from "utils/request";
import FormInput from "components/form-input";
import LayerInfo from "./layer-info";

const TabTitle = ({ icon, title, onClick }) => (
  <div className="d-flex align-items-center gap-2" onClick={onClick}>
    <i className={icon} />
    <p>{title}</p>
  </div>
);

const SidebarPetaDPI = () => {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
  });

  const {
    openBottomBar,
    activeKeyTabSidebar,
    loading: loadingRequestMap,
  } = useSelector((state) => state.map);

  const dispatch = useDispatch();

  const handleFilterLayer = (values) => {
    let payloadFilter = {
      year: values?.year?.value,
      tipe_id: values?.tipe_id?.value,
      tipe_nama: values?.tipe_id?.label,
    };

    if (values?.status?.value !== "all") {
      payloadFilter = {
        ...payloadFilter,
        status: values?.status?.value,
      };
    }

    const urlFilter = `/api/dpi-kecamatan/map/?tipe_id=${
      payloadFilter.tipe_id
    }&year=${payloadFilter.year}${
      payloadFilter.status ? `&status=${payloadFilter.status}` : ""
    }`;

    dispatch(setLoadingMap(true));

    request
      .get(urlFilter)
      .then((res) => {
        const mapURL = replaceToFullPath(res.data.url);

        dispatch(setMapURL(mapURL));

        fetch(mapURL)
          .then((isRes) => isRes.json())
          .then((response) => {
            const geoData = response;

            dispatch(setGeoJSONData(geoData));
            dispatch(setFilterGeoJSON(payloadFilter));
          })
          .finally(() => dispatch(setLoadingMap(false)));
      })
      .catch(() => dispatch(setLoadingMap(false)));
  };

  const handleRequestDPI = async (searchValue) => {
    try {
      const response = await request.get(`/api/dpi/?search=${searchValue}`);

      return response.data.data.map((el) => ({
        label: ucFirst(el.nama),
        value: el.id,
      }));
    } finally {
    }
  };

  return (
    <Tabs
      activeKey={activeKeyTabSidebar}
      className="d-flex justify-content-around mb-5"
    >
      <Tab
        eventKey="layer"
        title={
          <TabTitle
            title="Layer"
            onClick={() => dispatch(setActiveKeySidebar("layer"))}
          />
        }
      >
        <Form autoComplete="off" onSubmit={handleSubmit(handleFilterLayer)}>
          <div
            className="px-4 pb-3"
            style={{ height: "calc(100vh - 310px)", overflow: "auto" }}
          >
            <div>
              <FormInput
                required
                name="year"
                label="Waktu"
                control={control}
                errors={errors}
                inputType="select"
                placeholder="Waktu"
                options={getYears().map((el) => ({
                  label: el,
                  value: el,
                }))}
              />

              <FormInput
                required
                name="tipe_id"
                label="Pilih DPI"
                control={control}
                errors={errors}
                inputType="select_sync"
                placeholder="Pilih DPI"
                options={handleRequestDPI}
              />

              <FormInput
                name="status"
                label="Status"
                errors={errors}
                control={control}
                inputType="select"
                options={STATUS_DPI}
                placeholder="Pilih Status"
              />
            </div>
          </div>

          <div
            className="px-3 mt-5"
            // style={{ position: "fixed", bottom: "10px", width: "300px" }}
          >
            <Button
              className="w-full mb-4"
              variant="primary"
              type="submit"
              disabled={!isValid || loadingRequestMap}
            >
              {loadingRequestMap ? "memuat data..." : "Lihat Peta"}
            </Button>

            <Button
              className="w-full"
              variant="warning"
              type="button"
              onClick={() => dispatch(setOpenBottomBar(!openBottomBar))}
            >
              {openBottomBar ? "Tutup Grafik" : "Buka Grafik"}
            </Button>
          </div>
        </Form>
      </Tab>

      <Tab
        eventKey="information"
        title={
          <TabTitle
            title="Informasi"
            onClick={() => dispatch(setActiveKeySidebar("information"))}
          />
        }
      >
        <div
          className="p-3"
          style={{ height: "calc(100vh - 170px)", overflow: "auto" }}
        >
          <LayerInfo />
        </div>
      </Tab>
    </Tabs>
  );
};

export default SidebarPetaDPI;
