import { Suspense, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Toaster } from "react-hot-toast";
import { BrowserRouter as Router } from "react-router-dom";
import L from "leaflet";
import { setFromLogin } from "redux/actions";
// import { SW_INIT, SW_UPDATE } from "redux/app/type";
import Routers from "routes";
import LoadingGlobal from "components/loading-global";
// import ModalUpdateApp from "components/modal-update-app";
import "leaflet/dist/leaflet.css";
import "react-leaflet-markercluster/dist/styles.min.css";
import "react-perfect-scrollbar/dist/css/styles.css";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const App = () => {
  // const {
  //   serviceWorkerInitialized,
  //   serviceWorkerUpdated,
  //   serviceWorkerRegistration,
  // } = useSelector((state) => state.app);

  const dispatch = useDispatch();

  // const updateServiceWorker = () => {
  //   const registrationWaiting = serviceWorkerRegistration.waiting;

  //   if (registrationWaiting) {
  //     registrationWaiting.postMessage({ type: "SKIP_WAITING" });

  //     registrationWaiting.addEventListener("statechange", (e) => {
  //       if (e.target.state === "activated") {
  //         window.location.reload();
  //       }
  //     });
  //   }
  // };

  useEffect(() => {
    dispatch(setFromLogin(false));
  }, []);

  return (
    <Router>
      <Suspense fallback={<LoadingGlobal />}>
        <Routers />
        <Toaster />

        {/* {serviceWorkerInitialized && <ModalUpdateApp type={SW_INIT} />}
        {serviceWorkerUpdated && (
          <ModalUpdateApp type={SW_UPDATE} onClick={updateServiceWorker} />
        )} */}
      </Suspense>
    </Router>
  );
};

export default App;
