import { useState } from "react";
import {
  Form,
  FormGroup,
  FormLabel,
  FormControl,
  FormText,
  InputGroup,
} from "react-bootstrap";
import { Controller } from "react-hook-form";
import Select from "react-select";
import MDIInput from "./mdi-input";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";
import SelectCreatable from "react-select/creatable";
import { INPUT_TYPE, REACT_SELECT_CUSTOM_STYLES } from "utils/constant";
import PriceInput from "./price-input";
import PhoneInput from "./phone-input";
import TextEditor from "./text-editor";

const FormInput = ({
  label,
  type,
  name,
  errorMessage,
  register,
  errors,
  control,
  required,
  placeholder,
  inputType,
  mb,
  key,
  height,
  isMulti,
  creatable,
  disabled,
  options,
  defaultValue,
  maxLength,
  minLength,
  message,
  hidden,
  setValueAs,
  boldLabel,
  isClearable,
  mdiInput,
  styles,
  setValue,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const checkPasswordType =
    type === "password" ? (showPassword ? "text" : "password") : type;

  return (
    <div>
      {mdiInput ? (
        <MDIInput
          id={name}
          label={label}
          placeholder={label}
          {...register(name, { required: required })}
        />
      ) : (
        <FormGroup className={mb}>
          {label && (
            <FormLabel htmlFor={name} className="text-sm">
              {label} {boldLabel && <b>{boldLabel}</b>}
            </FormLabel>
          )}
          {inputType === INPUT_TYPE.INPUT ? (
            <InputGroup>
              <FormControl
                id={name}
                type={checkPasswordType}
                placeholder={placeholder}
                {...register(name, {
                  required: required,
                  maxLength: maxLength,
                  minLength: minLength,
                  setValueAs: setValueAs,
                })}
                hidden={hidden}
                isInvalid={errors[name]}
              />

              {type === "password" && (
                <button
                  type="button"
                  className="btn"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <i className="bi bi-eye-fill" />
                  ) : (
                    <i className="bi bi-eye-slash-fill" />
                  )}
                </button>
              )}
            </InputGroup>
          ) : inputType === INPUT_TYPE.TEXTAREA ? (
            <textarea
              className={`form-control${errors[name] ? " is-invalid" : ""}`}
              type={type}
              style={{ height: height }}
              placeholder={placeholder}
              {...register(name, {
                required: required,
                setValueAs: setValueAs,
              })}
              isInvalid={errors[name]}
              rows="2"
            ></textarea>
          ) : inputType === INPUT_TYPE.PRICE ? (
            <Controller
              name={name}
              control={control}
              defaultValue={defaultValue ?? ""}
              render={({ field: { onChange, value } }) => (
                <FormControl
                  type={type}
                  placeholder={placeholder}
                  prefix="Rp. "
                  as={PriceInput}
                  value={value}
                  disabled={disabled}
                  defaultValue={defaultValue}
                  onChange={(val) => onChange(val)}
                />
              )}
            />
          ) : inputType === INPUT_TYPE.SELECT ? (
            <Controller
              name={name}
              control={control}
              rules={{ required: required }}
              defaultValue={null}
              key={key}
              render={({ field: { onChange, value } }) => (
                <Select
                  id={name}
                  maxMenuHeight={height}
                  isMulti={isMulti}
                  isClearable={isClearable}
                  isDisabled={disabled}
                  value={value}
                  options={options}
                  placeholder={placeholder}
                  onChange={(val) => onChange(val)}
                  styles={{
                    ...styles,
                    ...REACT_SELECT_CUSTOM_STYLES,
                  }}
                />
              )}
            />
          ) : inputType === INPUT_TYPE.SELECT_CREATABLE ? (
            <Controller
              name={name}
              control={control}
              rules={{ required: required }}
              defaultValue={null}
              key={key}
              render={({ field: { onChange, value } }) => (
                <SelectCreatable
                  maxMenuHeight={height}
                  isMulti={isMulti}
                  creatable={creatable}
                  isDisabled={disabled}
                  value={value}
                  options={options}
                  placeholder={placeholder}
                  onChange={(val) => onChange(val)}
                  styles={{
                    ...styles,
                    ...REACT_SELECT_CUSTOM_STYLES,
                  }}
                />
              )}
            />
          ) : inputType === INPUT_TYPE.SELECT_SYNC ? (
            <Controller
              name={name}
              control={control}
              rules={{ required: required }}
              defaultValue={null}
              render={({ field: { onChange, value } }) => (
                <AsyncSelect
                  value={value}
                  cacheOptions
                  defaultOptions
                  isMulti={isMulti}
                  loadOptions={options}
                  isClearable={isClearable}
                  isDisabled={disabled}
                  placeholder={placeholder}
                  onChange={(val) => onChange(val)}
                  styles={{
                    ...styles,
                    ...REACT_SELECT_CUSTOM_STYLES,
                  }}
                />
              )}
            />
          ) : inputType === INPUT_TYPE.TEXT_EDITOR ? (
            <TextEditor
              name={name}
              control={control}
              required={required}
              setValue={setValue}
              defaultValue={defaultValue}
            />
          ) : inputType === INPUT_TYPE.PHONE ? (
            <Controller
              name={name}
              control={control}
              defaultValue={defaultValue ?? ""}
              render={({ field: { onChange, value } }) => (
                <FormControl
                  type={type}
                  placeholder={placeholder}
                  prefix="Rp. "
                  as={PhoneInput}
                  value={value}
                  disabled={disabled}
                  defaultValue={defaultValue}
                  onChange={(val) => onChange(val)}
                />
              )}
            />
          ) : null}
          {message && <FormText>{message}</FormText>}
          {errors[name] && (
            <Form.Control.Feedback className="d-block" type="invalid">
              {errors[name]?.message ? errors[name]?.message : errorMessage}
            </Form.Control.Feedback>
          )}
        </FormGroup>
      )}
    </div>
  );
};

FormInput.defaultProps = {
  type: "text",
  inputType: "input",
  mb: "mb-3",
  setValueAs: (val) => val,
  setValue: () => {},
};

FormInput.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  errorMessage: PropTypes.string,
  register: PropTypes.func,
  errors: PropTypes.object,
  control: PropTypes.func,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  inputType: PropTypes.string,
  mb: PropTypes.string,
  key: PropTypes.string,
  height: PropTypes.string,
  isMulti: PropTypes.bool,
  creatable: PropTypes.bool,
  disabled: PropTypes.bool,
  options: PropTypes.func,
  defaultValue: PropTypes.func,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  message: PropTypes.string,
  hidden: PropTypes.bool,
  setValueAs: PropTypes.func,
  boldLabel: PropTypes.string,
  mdiInput: PropTypes.bool,
  styles: PropTypes.object,
  isClearable: PropTypes.bool,
  setValue: PropTypes.func,
};

export default FormInput;
