import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useRoutes } from "react-router-dom";
import { setMenu } from "redux/actions";
import { routes } from "./routes";

const Routers = () => {
  const dispatch = useDispatch();
  const routing = useRoutes(routes);

  useEffect(() => {
    dispatch(setMenu(routes));
  }, [routes]);

  return routing;
};

export default Routers;
