import {
  SET_FILTER_GEOJSON,
  SET_GEOJSON_DATA,
  SET_META_MAP,
  SET_LOADING_MAP,
  SET_LAYER_INFO,
  SET_ACTIVE_KEY_SIDEBAR,
  SET_OPEN_BOTTOM_BAR,
  UPDATE_GEOJSON_DATA,
  SET_MAP_URL,
} from "./type";

export const setFilterGeoJSON = (filterGEO) => ({
  type: SET_FILTER_GEOJSON,
  payload: filterGEO,
});

export const setMetaMap = (metaMap) => ({
  type: SET_META_MAP,
  payload: metaMap,
});

export const setGeoJSONData = (geoJSONData) => ({
  type: SET_GEOJSON_DATA,
  payload: geoJSONData,
});

export const setLoadingMap = (loadingMap) => ({
  type: SET_LOADING_MAP,
  payload: loadingMap,
});

export const setLayerInfo = (layerInfo) => ({
  type: SET_LAYER_INFO,
  payload: layerInfo,
});

export const setActiveKeySidebar = (activeKeySidebar) => ({
  type: SET_ACTIVE_KEY_SIDEBAR,
  payload: activeKeySidebar,
});

export const setOpenBottomBar = (openBottomBar) => ({
  type: SET_OPEN_BOTTOM_BAR,
  payload: openBottomBar,
});

export const updateGeoJSONData = (geoData) => ({
  type: UPDATE_GEOJSON_DATA,
  payload: geoData,
});

export const setMapURL = (mapURL) => ({
  type: SET_MAP_URL,
  payload: mapURL,
});
