import { useState } from "react";
import { Modal, Button, FormGroup, FormLabel } from "react-bootstrap";
import PropTypes from "prop-types";
import axios from "axios";
import fileDownload from "js-file-download";
import leafletImage from "leaflet-image";
import { b64toBlob } from "utils/helper";
import { useSelector } from "react-redux";
import { useMap } from "react-leaflet";

const PrintModal = ({ isModalOpen, onCloseModal }) => {
  const [printTipe, setPrintTipe] = useState("pdf");

  const { url: mapURL } = useSelector((state) => state.map);

  const map = useMap();

  const handlePrintMap = () => {
    if (printTipe === "json") {
      axios
        .get(mapURL, {
          responseType: "blob",
        })
        .then((res) => fileDownload(res.data, "data.json"));
    } else {
      leafletImage(map, (_, canvas) => {
        const blob = b64toBlob(canvas.toDataURL());

        fileDownload(blob, "map.png");
      });
    }
  };

  return (
    <Modal show={isModalOpen} onHide={onCloseModal} centered>
      <Modal.Header className="bg-primary" closeButton>
        <Modal.Title className="text-white">Cetak Peta</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <FormGroup>
          <FormLabel className="text-md">Tipe</FormLabel>

          <select
            className="form-control"
            onChange={(val) => setPrintTipe(val.target.value)}
          >
            <option value="json">json</option>
            <option value="png">png</option>
          </select>
        </FormGroup>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="danger" onClick={onCloseModal}>
          Batal
        </Button>
        <Button variant="primary" onClick={handlePrintMap}>
          Cetak
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

PrintModal.propTypes = {
  isModalOpen: PropTypes.bool,
  onCloseModal: PropTypes.func,
};

export default PrintModal;
